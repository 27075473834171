<div class="point" *ngIf="showPoint">
	<p>
		A cada R$ 1 ganhe
		<strong>{{ benefit?.points }} {{ benefit?.points === 1 ? 'Milha' : 'Milhas' }}</strong>
	</p>
</div>

<div class="pointPass">
	<div class="pointPass__icon">
		<p><i class="fa fa-safebox"></i></p>
	</div>
	<div class="pointPass__pass">
		<p>Cliente Clube e</p>
		<p>LATAM Pass Itaú</p>
	</div>
	<div class="pointPass__value">
		<p>A cada R$ 1 ganhe até</p>
		<p id="values">{{ multiplyPoints }} Milhas</p>
	</div>
</div>
