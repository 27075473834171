<ng-template #templateViagens>
	<p class="subtitle">VIAGENS</p>
	<div class="menu">
		<div class="menu__box">
			<a href="https://latampass.latam.com/pt_br/passagens" class="menu__link"> Buscar Passagens </a>
			<a
				href="https://latampass.latam.com/pt_br/viagem/usar-pontos-para-voar/regras-de-resgate/latam"
				class="menu__link"
			>
				Usar milhas para voar
			</a>
			<a href="https://www.latam.com/retros/" class="menu__link"> Solicitar milhas Pendentes em Voo </a>
		</div>
		<div class="menu__box separator">
			<a href="https://latampass.latam.com/pt_br/viagem/hospedagens" class="menu__link"> Hospedagem </a>
			<a href="https://latampass.latam.com/pt_br/viagem/aluguel-de-carro" class="menu__link">
				Aluguel de carros
			</a>
			<a href="https://latampass.latam.com/pt_br/viagem/latam-travel" class="menu__link">
				Pacotes de Viagem
			</a>
		</div>
		<div class="menu__box separator">
			<a href="https://latampass.latam.com/pt_br/categorias-elite" class="menu__link">
				Categorias Elite
			</a>
			<a
				href="https://latampass.latam.com/pt_br/latampass-itaucard/beneficios-mastercard"
				class="menu__link"
			>
				Benefícios LATAM Pass Itaú
			</a>
			<a href="https://latampass.latam.com/pt_br/clube" class="menu__link"> Clube LATAM Pass </a>
		</div>
		<div class="menu__img">
			<a href="https://latampass.latam.com/pt_br/latampass-itaucard">
				<img class="wImg" src="./../assets/images/mViagens.png" />
			</a>
		</div>
	</div>
</ng-template>

<ng-template #templateShopping>
	<p class="subtitle">TROQUE</p>
	<div class="menu">
		<div class="menu__box">
			<a href="https://resgatepontos.latampass.latam.com/use-pontos" class="menu__link">
				Troque milhas
			</a>
			<a href="https://resgatepontos.latampass.latam.com/ganhe-pontos" class="menu__link">
				Ganhar Milhas
			</a>
			<a href="https://latampass.latam.com/pt_br/resgate-pontos/parceiros" class="menu__link">
				Explorar parceiros
			</a>
		</div>
		<div class="menu__img">
			<a href="https://latampass.latam.com/pt_br/latampass-itaucard">
				<img class="wImg" src="./../assets/images/mViagens.png" />
			</a>
		</div>
		<div class="menu__img">
			<a
				href="https://resgatepontos.latampass.latam.com/trocar-pontos/accor/transferencia-de-pontos-para-accor/TA008"
			>
				<img class="wImg" src="./../assets/images/mAll.png" />
			</a>
		</div>
		<div class="menu__img">
			<a href="https://resgatepontos.latampass.latam.com/veiculo/movida">
				<img class="wImg" src="./../assets/images/mMovida.png" />
			</a>
		</div>
	</div>
</ng-template>

<ng-template #templateJuntePontos>
	<div class="menu">
		<div>
			<p class="subtitle">JUNTE</p>
			<div class="menu__box">
				<a href="https://latampass.latam.com/pt_br/juntar-pontos" class="menu__link">
					Junte milhas
				</a>
				<a href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos" class="menu__link">
					Como juntar milhas
				</a>
				<a
					href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos/solicitar-pontos-em-voo"
					class="menu__link"
				>
					Juntar milhas voando
				</a>
			</div>
		</div>
		<div>
			<p class="subtitle subMargin">EXPLORE</p>
			<div class="menu__box separator">
				<a
					href="https://latampass.latam.com/pt_br/ganhar-pontos/cartoes-de-bancos"
					class="menu__link"
				>
					Cartões de banco
				</a>
				<a href="https://www.pontosmultiplus.com.br/facilidades/compradepontos" class="menu__link">
					Comprar Milhas
				</a>
			</div>
		</div>
		<div>
			<p class="subtitle subMargin">ACELERE</p>
			<div class="menu__box separator">
				<a href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos" class="menu__link">
					Acelere suas milhas
				</a>
				<a href="https://latampass.latam.com/pt_br/latampass-itaucard" class="menu__link">
					Cartão LATAM Pass Itaú
				</a>
				<a href="https://latampass.latam.com/pt_br/clube" class="menu__link"> Clube LATAM Pass </a>
			</div>
		</div>
		<div class="menu__img subMarginTop">
			<a href="https://latampass.latam.com/pt_br/latampass-itaucard">
				<img class="wImg" src="./../assets/images/mViagens.png" />
			</a>
		</div>
	</div>
</ng-template>

<ng-template #templateAcelere>
	<p class="subtitle">ACELERE SEUS milhaS</p>
	<div class="menu">
		<div class="menu__box">
			<a href="https://latampass.latam.com/pt_br/latampass-itaucard" class="menu__link">
				Cartão LATAM Pass Itaú
			</a>
			<a href="https://latampass.latam.com/pt_br/clube" class="menu__link"> Clube LATAM Pass </a>
			<a href="https://www.pontosmultiplus.com.br/facilidades/compradepontos" class="menu__link">
				Compre Milhas
			</a>
		</div>
		<div class="menu__box separator">
			<a
				href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos/multiplicar"
				class="menu__link"
			>
				Multiplique seus milhas
			</a>
			<a href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos/renovar" class="menu__link">
				Renove suas milhas
			</a>
			<a
				href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos/transferir"
				class="menu__link"
			>
				Transfira seus milhas
			</a>
		</div>
		<div class="menu__box separator">
			<a href="https://latampass.latam.com/pt_br/passagens" class="menu__link"> Resgate aéreo </a>
		</div>
		<div class="menu__img">
			<a href="https://latampass.latam.com/pt_br/latampass-itaucard">
				<img class="wImg" src="./../assets/images/mViagens.png" />
			</a>
		</div>
	</div>
</ng-template>

<ng-template #templateMais>
	<div>
		<div class="menu">
			<div>
				<p class="subtitle">SOBRE</p>
				<div class="menu__box">
					<a href="https://latampass.latam.com/pt_br/sobre-o-programa" class="menu__link">
						Sobre o programa
					</a>
					<a href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos" class="menu__link">
						Como juntar milhas
					</a>
					<a
						href="https://latampass.latam.com/pt_br/viagem/usar-pontos-para-voar/regras-de-resgate/latam"
						class="menu__link"
					>
						Use milhas para voar
					</a>
				</div>
			</div>
			<div>
				<p class="subtitle subMargin">EXTRA</p>
				<div class="menu__box separator">
					<a href="https://latampass.latam.com/pt_br/baixe-o-app" class="menu__link">
						Baixe o app
					</a>
					<a href="https://latampass.latam.com/pt_br/viagem/seguros" class="menu__link">
						Seguros
					</a>
					<a
						href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos/solicitar-pontos-em-voo/companhias-parceiras"
						class="menu__link"
					>
						Compamhias aereas
					</a>
				</div>
			</div>
			<div>
				<p class="subtitle subMargin">BENEFÍCIOS</p>
				<div class="menu__box separator">
					<a href="https://latampass.latam.com/pt_br/categorias-elite" class="menu__link">
						Categorias Elite
					</a>
					<a href="https://latampass.latam.com/pt_br/latampass-itaucard" class="menu__link">
						Cartão LATAM Pass Itaú
					</a>
					<a href="https://latampass.latam.com/pt_br/clube" class="menu__link">
						Clube LATAM Pass
					</a>
				</div>
			</div>
			<div class="menu__img subMarginTop">
				<a href="https://latampass.latam.com/pt_br/latampass-itaucard">
					<img class="wImg" src="./../assets/images/mViagens.png" />
				</a>
			</div>
		</div>
	</div>
</ng-template>

<div [ngTemplateOutlet]="templateChoice"></div>
