<div class="payment px-16">
	<form class="payment__form" [formGroup]="form">
		<div class="payment__input">
			<input
				type="text "
				id="valor"
				placeholder="Digite o valor a pagar"
				class="payment__input__totalValue"
				currencyMask
				[options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
				placeholder="Digite o valor a pagar"
				type="text"
				formControlName="totalValue"
				inputmode="numeric"
			/>
		</div>

		<div class="payment__infoOne position border top pBottom">
			<p class="payment__title">Valor da conta</p>
			<p class="payment__value">{{ bill$ | async | currency : 'BRL' }}</p>
		</div>

		<div class="payment__infoTwo border top" *ngIf="!removeGratuityValue">
			<div class="payment__infoUp position">
				<p class="payment__title">
					Gorjeta garçom
					<i
						class="fa fa-info colorBlue"
						(mouseover)="showTip = true"
						(mouseout)="showTip = false"
						(click)="showTip = !showTip"
					>
						<div class="tip" *ngIf="showTip">O total de milhas não inclui a gorjeta.</div></i
					>
				</p>
				<p class="payment__value">{{ billTip$ | async | currency : 'BRL' }}</p>
			</div>
			<div class="payment__infoDown position">
				<button class="payment__titleTwo" (click)="modalService.open(modalTipId)">
					Incluir gorjeta adicional
				</button>
				<p class="payment__valueTwo" *ngIf="aditionalBillTip$ | async as aditionalTip">
					{{ aditionalTip | currency : 'BRL' }}
				</p>
			</div>
		</div>

		<div class="payment__infoThree position border top pBottom">
			<p class="payment__title">
				Você receberá
				<i
					class="fa fa-info colorBlue"
					(mouseover)="showTipTwo = true"
					(mouseout)="showTipTwo = false"
					(click)="showTipTwo = !showTipTwo"
				>
					<div class="tip" *ngIf="showTipTwo">As milhas serão creditados em até 48h.</div>
				</i>
			</p>
			<p class="payment__value colorBlue" *ngIf="points$ | async as points">{{ points }} i</p>
		</div>

		<div class="payment__infoFour position top pBottom">
			<p class="payment__title">Valor total a pagar</p>
			<p class="payment__value" *ngIf="totalValue$ | async as totalValue">
				{{ totalValue | currency : 'BRL' }}
			</p>
		</div>

		<button
			class="btn btn--primary"
			(click)="goToConfirmPayments()"
			[disabled]="form.invalid"
			[class.btn--disabled]="form.invalid"
		>
			Ir para pagamento
		</button>
	</form>
</div>

<app-modal [id]="modalTipId" top="15%" [preventOutsideClickClose]="true">
	<app-modal-tip
		[id]="modalTipId"
		[billTip]="billTip$ | async"
		[suggestedTip]="suggestedTip"
		(changedTip)="changeTip($event)"
	></app-modal-tip>
</app-modal>
