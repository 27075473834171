<app-header></app-header>

<div class="colorBody">
	<div class="breadcrumb">
		<app-breadcrumb></app-breadcrumb>
	</div>
	<div class="img">
		<div class="img__desk">
			<img src="./../assets/images/carro1.jpg" />
		</div>
		<div class="img__mobile">
			<img src="./../assets/images/carro2.jpg" />
		</div>
	</div>

	<div class="infosMobile">
		<div class="info px-16">
			<div class="info__inside">
				<p class="numberInfo">1</p>
				<div class="textInfo">
					<p class="textInfo__title">Escolha o restaurante</p>
					<p class="textInfo__text">que deseja na sua localização</p>
				</div>
			</div>
		</div>

		<div class="info px-16">
			<div class="info__inside">
				<p class="numberInfo">2</p>
				<div class="textInfo">
					<p class="textInfo__title">Selecione a data</p>
					<p class="textInfo__text">e reserve uma mesa</p>
				</div>
			</div>
		</div>

		<div class="info px-16">
			<div class="info__inside">
				<p class="numberInfo">3</p>
				<div class="textInfo">
					<p class="textInfo__title">Pague a conta na categoria Restaurantes</p>
					<p class="textInfo__text">em “Minhas Reservas” e aproveite!</p>
				</div>
			</div>
		</div>

		<div class="info px-16">
			<div class="info__inside">
				<p class="numberInfo">4</p>
				<div class="textInfo">
					<p class="textInfo__title">Ganhe benefícios</p>
					<p class="textInfo__text">
						acumule milhas e troque por passagens. O cliente CLube LATAM Pass ganha ainda mais
						milhas
					</p>
				</div>
			</div>
		</div>
	</div>

	<div class="infosDesk">
		<div>
			<div class="info px-16">
				<div class="info__inside">
					<p class="numberInfo">1</p>
					<div class="textInfo">
						<p class="textInfo__title">Escolha o restaurante</p>
						<p class="textInfo__text">que deseja na sua localização</p>
					</div>
				</div>
			</div>

			<div class="info px-16 topDivider">
				<div class="info__inside">
					<p class="numberInfo">3</p>
					<div class="textInfo">
						<p class="textInfo__title">Pague a conta na categoria Restaurantes</p>
						<p class="textInfo__text">em “Minhas Reservas” e aproveite!</p>
					</div>
				</div>
			</div>
		</div>

		<div>
			<div class="info px-16">
				<div class="info__inside">
					<p class="numberInfo">2</p>
					<div class="textInfo">
						<p class="textInfo__title">Selecione a data</p>
						<p class="textInfo__text">e reserve uma mesa</p>
					</div>
				</div>
			</div>
			<div class="info px-16 topDivider">
				<div class="info__inside">
					<p class="numberInfo">4</p>
					<div class="textInfo">
						<p class="textInfo__title">Ganhe benefícios</p>
						<p class="textInfo__text">
							acumule milhas e troque por passagens. O cliente CLube LATAM Pass ganha ainda mais
							milhas
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<hr class="hr" />

	<div class="alert px-16">
		<app-alert></app-alert>
	</div>

	<div class="seeMore">
		<button class="btn btn--primary" routerLink="/gastronomia">Ver restaurantes próximos</button>
	</div>

	<div class="mail">
		<img class="mail__img" src="./../assets/images/person.png" />
		<div class="mail__info">
			<p class="mail__infoText">
				Envie sua sugestão de restaurante para fazer parte do Latam Pass Gastronomia
			</p>

			<a
				class="mail__infoLink"
				href="mailto:latampassgastronomia@tagme.com.br?subject=Sugestão novo restaurante"
				>Enviar Sugestão<i class="fa fa-arrow" style="font-size: 10px; margin-left: 5px"></i
			></a>
		</div>
		<div class="mail__ornamentMobile mobile">
			<img src="./../assets/images/union.png" />
		</div>
		<div class="mail__ornamentDesk desktop">
			<img src="./../assets/images/unionD.png" />
		</div>
	</div>
</div>

<app-footer></app-footer>
