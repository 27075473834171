import { AfterViewInit, Component, Input } from '@angular/core'
import { ModalService } from '../modal/modal.service'
import { Store } from '@ngrx/store'
import { AppState } from 'src/state/app.state'
import { getErrorLogin, isLoggedIn } from 'src/state/client/client.reducers'
import { take } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { ClientActions } from 'src/state/client/client.actions'
import { Actions, ofType } from '@ngrx/effects'
import { VenueActions, getVenues } from 'src/state/venue/venue.actions'
import { LOCAL_CLIENT_ID, LOCAL_TOKEN, LocalStorageService } from 'src/shared/services/local-storage.service'
import { GeolocationService } from 'src/shared/services/geo-location.service'

@Component({
	selector: 'app-handle-login-token',
	template: `
		<app-modal [id]="modal.loginFailure" top="25%">
			<app-modal-basic
				[id]="modal.loginFailure"
				title="Login falhou"
				*ngIf="errorLogin$ | async as error"
			>
				{{ error }}
			</app-modal-basic>
		</app-modal>

		<app-modal [id]="modal.loginSuccess" top="25%">
			<app-modal-basic [id]="modal.loginSuccess" title="Login realizado com sucesso!">
				Acumule milhas e troque por passagens. O cliente Clube LATAM Pass ganha ainda mais milhas.
				<button class="btn btn--primary" (click)="modalService.close(modal.loginSuccess)">Ok</button>
			</app-modal-basic>
		</app-modal>

		<app-modal [id]="modal.loginLoading" top="25%" [preventOutsideClickClose]="true">
			<app-modal-basic [id]="modal.loginLoading" title="Aguarde" [hasClose]="false">
				<app-spinner></app-spinner>
				Realizando login...
			</app-modal-basic>
		</app-modal>
	`,
})
export class HandleLoginTokenComponent implements AfterViewInit {
	@Input() page: 'venue-list' | 'venue-details' | undefined
	modal = {
		loginFailure: 'modal-login-failure-id',
		loginSuccess: 'modal-login-success-id',
		loginLoading: 'modal-login-loading-id',
	}
	errorLogin$ = this.store.select(getErrorLogin)

	constructor(
		public modalService: ModalService,
		private store: Store<AppState>,
		private route: ActivatedRoute,
		private router: Router,
		private actions$: Actions,
		private localStorageService: LocalStorageService,
		private geoLocation: GeolocationService
	) {}

	ngAfterViewInit(): void {
		const dispatch = this.getDispatch()
		this.handleLoginToken(dispatch)
	}

	private handleLoginToken(dispatch: () => void) {
		const subscribeToLoading = (dispatch: () => void) => {
			this.actions$.pipe(ofType(ClientActions.loginClientSuccess), take(1)).subscribe(() => {
				this.modalService.close(this.modal.loginLoading)
				this.modalService.open(this.modal.loginSuccess)
				dispatch()
			})

			this.actions$.pipe(ofType(ClientActions.loginClientFailure), take(1)).subscribe(() => {
				this.modalService.close(this.modal.loginLoading)
				this.modalService.open(this.modal.loginFailure)
				dispatch()
			})
		}
		setTimeout(() => {
			this.store
				.select(isLoggedIn)
				.pipe(take(1))
				.subscribe(isLoggedIn => {
					if (isLoggedIn) {
						dispatch()
						return
					}
					const latamToken = this.route.snapshot.queryParamMap.get('partner_token')
					// If has latamToken and is not logged in, try login sso
					if (latamToken && !isLoggedIn) {
						this.modalService.open(this.modal.loginLoading)
						this.store.dispatch(ClientActions.loginClientSSO({ token: latamToken }))
						subscribeToLoading(dispatch)
						return
					}
					// If doesn't have latam token, try getting token from localStorage and normal login
					const clientId = this.localStorageService.getTime(LOCAL_CLIENT_ID)
					const accessToken = localStorage.getItem(LOCAL_TOKEN)
					if (clientId && accessToken) {
						this.store.dispatch(ClientActions.loginClient({ id: clientId }))
						this.actions$
							.pipe(ofType(ClientActions.loginClientSuccess), take(1))
							.subscribe(() => {
								dispatch()
							})

						this.actions$
							.pipe(ofType(ClientActions.loginClientFailure), take(1))
							.subscribe(() => {
								this.modalService.open(this.modal.loginFailure)
								dispatch()
							})
						return
					} else {
						dispatch()
					}
				})
		}, 0)
	}

	private getDispatch() {
		if (this.page === 'venue-details') {
			const id = this.route.snapshot.paramMap.get('venueId') as string
			return () => {
				this.store.dispatch(VenueActions.loadOneVenue({ venueId: id }))
				this.router.navigate([], { queryParams: {} })
			}
		}
		if (this.page === 'venue-list') {
			return () => {
				// Get currentLocation
				this.geoLocation.getCurrentLocation()

				this.geoLocation.location$.subscribe({
					next: ({ state, position }) => {
						if (state === 'denied' || state === 'prompt') {
							this.store.dispatch(VenueActions.getVenues())
							return
						}
						if (state === 'granted' && position) {
							this.store.dispatch(
								VenueActions.setLocation({
									latitude: position.latitude,
									longitude: position.longitude,
									maxDistance: 42_500_000,
								})
							)
						}
					},
				})

				this.router.navigate([], { queryParams: {} })
			}
		}
		return () => {
			this.router.navigate([], { queryParams: {} })
		}
	}
}
