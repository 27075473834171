export const environment = {
	production: false,
	env: 'staging',
	channelsAPI: {
		baseUrl: 'https://channelsapi-stg.tagme.com.br',
	},
	liveMenu: {
		url: 'https://live-menu-staging.tagme.com.br/menu',
	},
	latamPassUrl: 'https://latampass.uat-mktplace-gopoints.com.br',
	latamUrl: 'https://latam-stg.tagme.com.br',
	partnerId: '643994cb6e7a2ca377e2400c', // Latam
	googleMapsAPIToken: 'AIzaSyAZ-N7OLjJFVViV6m4Rk5BV7eeuw97DJx8',
}
