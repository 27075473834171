<div class="menuOne">
	<div class="menuOne__box px-16">
		<a class="menuOne__box__link" href="https://www.latamairlines.com/br/pt">
			<i class="tagmeicon tagmeicon-seta"></i>
			ir para LATAM Airlines
		</a>
		<div class="menuOne__box__right">
			<a href="#" class="menuOne__box__link">Brasil</a>
			<a href="https://www.latamairlines.com/br/pt/central-ajuda" class="menuOne__box__link desktop"
				>Central de Ajuda</a
			>
		</div>
	</div>
</div>

<div class="menu">
	<div class="menu__box px-16">
		<div class="menu__box__mobile">
			<div class="menu__box__mobile__one">
				<a href="https://latampass.latam.com/pt_br/">
					<img class="menu__box__mobile__oneImg" src="./../assets/images/logo-latam.svg" />
				</a>
			</div>
			<ng-container *ngIf="{ client: client$ | async } as obj">
				<div class="menu__box__mobile__two">
					<button (click)="menuUser()">
						<i class="fa fa-account menu__box__mobile__twoIcon"></i>
						<div class="badge__inner-container" *ngIf="obj.client && hasPaymentReady">
							<div class="badge__circle">
								<span class="badge__circle__notification">1</span>
							</div>
						</div>
					</button>
				</div>
			</ng-container>
			<div class="menu__box__mobile__three">
				<button (click)="showMenuMobile()">
					<i class="fa fa-menu-hamburger menu__box__mobile__threeIcon"></i>
				</button>
			</div>
		</div>

		<div [class.show]="showDivMobile" class="menuMobile">
			<div class="menuMobile__box">
				<button (click)="showMenuMobile()" class="menuMobile__boxBtn">
					<i class="fa fa-close blueDark" style="font-size: 18px"></i>
				</button>

				<!--Pt 1-->
				<div class="menuMobile__boxLinks">
					<a href="https://latampass.latam.com/pt_br/passagens"
						><button class="menuMobile__boxLink">Passagens</button></a
					>
				</div>

				<!--Pt 2-->
				<div class="menuMobile__boxLinks">
					<button class="menuMobile__boxLink" (click)="showMenu2Sub()">
						Juntar <span style="color: #ed1650">milhas</span>
					</button>
					<i
						(click)="showMenu2Sub()"
						class="tagmeicon tagmeicon-seta menuMobile__boxIcon"
						[ngClass]="{ rotate: showSubMenu2 }"
					></i>
					<div class="menuMobile__boxSub" *ngIf="showSubMenu2">
						<p class="menuMobile__boxSub__text">
							Juntar milhas com o cartão de crédito pode ser uma descoberta incrível de
							vantagens para quem deseja economizar e obter benefícios extras em suas compras.
						</p>
						<p class="menuMobile__boxSub__subTitle">NA SUA VIAGEM</p>
						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos/solicitar-pontos-em-voo"
								class="menuMobile__boxSub__link"
							>
								Voos LATAM
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/viagem/hospedagens"
								class="menuMobile__boxSub__link"
							>
								Reservas de Hotéis
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/viagem/aluguel-de-carro"
								class="menuMobile__boxSub__link"
							>
								Aluguel de Carro
							</a>
						</div>

						<div class="menuMobile__boxSub__links">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/viagem/pacotes-de-viagem"
								class="menuMobile__boxSub__link"
							>
								Pacotes de Viagem
							</a>
						</div>

						<p class="menuMobile__boxSub__subTitle">NO DIA A DIA</p>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/ganhar-pontos/cartoes-de-bancos"
								class="menuMobile__boxSub__link"
							>
								Transfira do cartão de crédito
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/latampass-itaucard"
								class="menuMobile__boxSub__link"
							>
								Cartão LATAM Pass Itaú
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/clube"
								class="menuMobile__boxSub__link"
							>
								Cartão LATAM Pass
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://www.pontosmultiplus.com.br/facilidades/compradepontos"
								class="menuMobile__boxSub__link"
							>
								Comprar milhas
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/junte-pontos/km-de-vantagens"
								class="menuMobile__boxSub__link"
							>
								Postos Ipiranga
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://resgatepontos.latampass.latam.com/home"
								class="menuMobile__boxSub__link"
							>
								Shopping LATAM Pass
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://gastronomia.latampass.latam.com/gastronomia"
								class="menuMobile__boxSub__link"
							>
								LATAM Pass Gastronomia
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/viagem/seguros"
								class="menuMobile__boxSub__link"
							>
								Seguros
							</a>
						</div>

						<div class="menuMobile__boxSub__links">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos/transferir"
								class="menuMobile__boxSub__link"
							>
								Transfira entre contas LATAM Pass
							</a>
						</div>
					</div>
				</div>

				<!--Pt 3-->
				<div class="menuMobile__boxLinks">
					<button class="menuMobile__boxLink" (click)="showMenu3Sub()">
						Usar <span style="color: #ed1650">milhas</span>
					</button>
					<i
						(click)="showMenu3Sub()"
						class="tagmeicon tagmeicon-seta menuMobile__boxIcon"
						[ngClass]="{ rotate: showSubMenu3 }"
					></i>
					<div class="menuMobile__boxSub" *ngIf="showSubMenu3">
						<p class="menuMobile__boxSub__text">
							Você tem milhas no seu banco ou cartão de crédito? É simples! Você faz o cadastro
							no LATAM Pass e transfere os milhas para o seu CPF! Depois disso, é só trocar por
							passagens aéreas ou produtos em nosso site!
						</p>
						<p class="menuMobile__boxSub__subTitle">NA SUA VIAGEM</p>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/passagens"
								class="menuMobile__boxSub__link"
							>
								Passagens
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://resgatepontos.latampass.latam.com/trocar-pontos/accor/transferencia-de-pontos-para-accor/TA008"
								class="menuMobile__boxSub__link"
							>
								Reserva de Hoteis
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/viagem/aluguel-de-carro"
								class="menuMobile__boxSub__link"
							>
								Aluguel de carros
							</a>
						</div>

						<div class="menuMobile__boxSub__links">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/viagem/pacotes-de-viagem"
								class="menuMobile__boxSub__link"
							>
								Pacotes de Viagem
							</a>
						</div>

						<p class="menuMobile__boxSub__subTitle">NO DIA A DIA</p>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://resgatepontos.latampass.latam.com/home"
								class="menuMobile__boxSub__link"
							>
								Produtos
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://shopping.latampass.latam.com/pt_br/hotsite?b=combustiveis"
								class="menuMobile__boxSub__link"
							>
								Vale Combustível
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://resgatepontos.latampass.latam.com/trocar-pontos/categoria/varejo-virtual/315?ordenacao=SCORE&habilitado=true&publicado=true&produtoHome=false&page=1&pageSize=12&categoriasGopoints=303&categoriaPai=303&relevancia=SCORE"
								class="menuMobile__boxSub__link"
							>
								Voucher
							</a>
						</div>

						<div class="menuMobile__boxSub__links">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://resgatepontos.latampass.latam.com/trocar-pontos/categoria/doacao-de-pontos/345?ordenacao=SCORE&habilitado=true&publicado=true&produtoHome=false&page=1&pageSize=12&&categoriasGopoints=315&categoriaPai=1&relevancia=SCORE"
								class="menuMobile__boxSub__link"
							>
								Doação de milhas
							</a>
						</div>
					</div>
				</div>

				<!--Pt 4-->
				<div class="menuMobile__boxLinks">
					<button class="menuMobile__boxLink" (click)="showMenu4Sub()">
						Benefícios <span style="color: #ed1650">Elite</span>
					</button>
					<i
						(click)="showMenu4Sub()"
						class="tagmeicon tagmeicon-seta menuMobile__boxIcon"
						[ngClass]="{ rotate: showSubMenu4 }"
					></i>
					<div class="menuMobile__boxSub" *ngIf="showSubMenu4">
						<p class="menuMobile__boxSub__text">
							As categorias Elite são as categorias do programa LATAM Pass. A cada voo, você
							junta milhas qualificáveis para subir de categoria e ter cada vez mais benefícios.
						</p>
						<p class="menuMobile__boxSub__subTitle">EM SEU VOO</p>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/categorias-elite/upgrade-de-cabine"
								class="menuMobile__boxSub__link"
							>
								Upgrade de cabine
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://www.latamairlines.com/br/pt/central-ajuda/perguntas/remarcacoes/passagens/adiantar-adiar-voo-hoje"
								class="menuMobile__boxSub__link"
							>
								Antecipar ou postergar voo
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://www.latamairlines.com/br/pt/experiencia/prepare-sua-viagem/bagagem"
								class="menuMobile__boxSub__link"
							>
								Bagagem
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://www.latamairlines.com/br/pt/experiencia/prepare-sua-viagem/assentos"
								class="menuMobile__boxSub__link"
							>
								Seleção de assento
							</a>
						</div>

						<div class="menuMobile__boxSub__links">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/viagem/assento-latam-mais"
								class="menuMobile__boxSub__link"
							>
								Assento LATAM+
							</a>
						</div>

						<p class="menuMobile__boxSub__subTitle">NO AEROPORTO</p>
						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/categorias-elite"
								class="menuMobile__boxSub__link"
							>
								Acesso Premium
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/categorias-elite"
								class="menuMobile__boxSub__link"
							>
								Sala Vip LATAM
							</a>
						</div>

						<div class="menuMobile__boxSub__links">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/categorias-elite"
								class="menuMobile__boxSub__link"
							>
								Embarque Premium
							</a>
						</div>

						<p class="menuMobile__boxSub__subTitle">TUDO SOBRE</p>
						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/categorias-elite"
								class="menuMobile__boxSub__link"
							>
								Categorias Elite
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/categorias-elite"
								class="menuMobile__boxSub__link"
							>
								Todos os benefícios
							</a>
						</div>
					</div>
				</div>

				<!--Pt 5-->
				<div class="menuMobile__boxLinks">
					<button class="menuMobile__boxLink" (click)="showMenu5Sub()">Descubra LATAM Pass</button>
					<i
						(click)="showMenu5Sub()"
						class="tagmeicon tagmeicon-seta menuMobile__boxIcon"
						[ngClass]="{ rotate: showSubMenu5 }"
					></i>
					<div class="menuMobile__boxSub" *ngIf="showSubMenu5">
						<p class="menuMobile__boxSub__text">
							Com a LATAM Pass, você tem acesso a um mundo de oportunidades para descobrir novas
							experiências. Não perca a chance de viver momentos inesquecíveis.
						</p>
						<p class="menuMobile__boxSub__subTitle">SOBRE O PROGRAMA</p>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/sobre-o-programa"
								class="menuMobile__boxSub__link"
							>
								Conheça LATAM Pass
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/fazer-cadastro"
								class="menuMobile__boxSub__link"
							>
								Como se Inscrever
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/categorias-elite"
								class="menuMobile__boxSub__link"
							>
								Como ser um sócio elite
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/vem-com-pontos"
								class="menuMobile__boxSub__link"
							>
								Vem com milhas
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos/solicitar-pontos-em-voo/validade-pontos"
								class="menuMobile__boxSub__link"
							>
								Vencimento de milhas
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos/renovar"
								class="menuMobile__boxSub__link"
							>
								Renovar minhas milhas
							</a>
						</div>

						<div class="menuMobile__boxSub__links" style="border-bottom: 1px solid #e1e5f5">
							<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
							<a
								href="https://latampass.latam.com/pt_br/descubra-latam-pass/termos-e-condicoes"
								class="menuMobile__boxSub__link"
							>
								Termos e Condições
							</a>
						</div>
					</div>
				</div>

				<a href="https://latampass.latam.com/pt_br/vem-com-pontos"
					><button class="menuMobile__btnOne">Iniciante no mundo de milhas?</button></a
				>
				<a href="https://latampass.latam.com/pt_br/latampass-itaucard"
					><button class="menuMobile__btnTwo">
						<p class="menuMobile__btnTwo__title">
							LATAM Pass<br />
							Itaucard
						</p>
						<p class="menuMobile__btnTwo__text">PEÇA O SEU <i class="fa fa-arrow"></i></p>
						<img class="menuMobile__btnTwo__img" src="./../assets/images/image33.png" />
					</button>
				</a>
			</div>
		</div>

		<div class="menu__box__desk">
			<div class="menu__box__desk__one">
				<a class="menu__box__desk__oneImg" href="https://latampass.latam.com/pt_br/">
					<img src="./../assets/images/logo-latam.svg" />
				</a>
			</div>
			<div class="menu__box__desk__two">
				<a href="https://latampass.latam.com/pt_br/passagens">
					<div class="menu__box__desk__twoBox">
						<button class="menu__box__desk__twoBtn" (mouseover)="exitMenu()">
							<p class="menu__box__desk__twoBtn__title">Passagens</p>
							<span class="menu__box__desk__twoBtn__sub" style="visibility: hidden">Two</span>
						</button>
					</div>
				</a>
				<a href="https://latampass.latam.com/pt_br/juntar-pontos">
					<div class="menu__box__desk__twoBox">
						<button class="menu__box__desk__twoBtn" (mouseover)="showMenuDesktop('menu2')">
							<p class="menu__box__desk__twoBtn__title">Juntar</p>
							<span class="menu__box__desk__twoBtn__sub">milhas</span>
							<i class="menu__box__desk__twoBtn__icon tagmeicon tagmeicon-seta"></i>
						</button>
					</div>
				</a>
				<a href="https://latampass.latam.com/pt_br/trocar-pontos">
					<div class="menu__box__desk__twoBox">
						<button class="menu__box__desk__twoBtn" (mouseover)="showMenuDesktop('menu3')">
							<p class="menu__box__desk__twoBtn__title">Usar</p>
							<span class="menu__box__desk__twoBtn__sub">milhas</span>
							<i class="menu__box__desk__twoBtn__icon tagmeicon tagmeicon-seta"></i>
						</button>
					</div>
				</a>
				<a href="https://latampass.latam.com/pt_br/categorias-elite">
					<div class="menu__box__desk__twoBox">
						<button class="menu__box__desk__twoBtn" (mouseover)="showMenuDesktop('menu4')">
							<p class="menu__box__desk__twoBtn__title">Benefícios</p>
							<span class="menu__box__desk__twoBtn__sub">Elite</span>
							<i class="menu__box__desk__twoBtn__icon tagmeicon tagmeicon-seta"></i>
						</button>
					</div>
				</a>
				<div class="menu__box__desk__twoBox">
					<button class="menu__box__desk__twoBtn" (mouseover)="showMenuDesktop('menu5')">
						<p class="menu__box__desk__twoBtn__title">Descubra</p>
						<span class="menu__box__desk__twoBtn__sub">LATAM Pass</span>
						<i class="menu__box__desk__twoBtn__icon tagmeicon tagmeicon-seta"></i>
					</button>
				</div>
			</div>
			<ng-container *ngIf="{ client: client$ | async } as obj">
				<div class="menu__box__desk__three">
					<a [href]="signUpUrl" *ngIf="!obj.client">
						<button class="btnMenu btnMenu--primary">Cadastre-se</button>
					</a>
					<a [href]="loginUrl" *ngIf="!obj.client">
						<button class="btnMenu btnMenu--secondary">Login</button>
					</a>
					<div *ngIf="obj.client">
						<div class="menuLogDesk">
							<button>
								<i class="fa fa-account menuLogDesk__icon" (click)="menuUserDesk()"></i>
								<div class="menuLogDesk__menu" *ngIf="showMenuUserDesk">
									<button class="menuLogDesk__menuBtn" routerLink="/gastronomia">
										Gastronomia
									</button>
									<button class="menuLogDesk__menuBtn" routerLink="/reservas-e-pagamento">
										Reservas e pagamento
									</button>
									<button class="menuLogDesk__menuBtn" routerLink="/carteira">
										Carteira
									</button>
									<a href="https://latampass.com/myaccount" style="font-size: 14px">
										<button class="menuLogDesk__menuBtn">Minha conta</button>
									</a>
									<button class="menuLogDesk__menuBtn" (click)="logout()">
										Sair agora
									</button>
								</div>
							</button>
							<div class="menuLogDesk__options">
								<p class="menuLogDesk__user" [title]="obj.client.fullName">
									{{ obj.client.fullName }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>

<div [class.show]="showDivDesktop" class="menuDesktop">
	<!--menu2-->
	<div class="menuDesktop__box" *ngIf="menuSelected == 'menu2'">
		<div class="menuDesktop__box__links">
			<p class="menuDesktop__box__linksTitle">NA SUA VIAGEM</p>
			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos/solicitar-pontos-em-voo"
					class="menuDesktop__box__link"
					>Voos LATAM</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://latampass.latam.com/pt_br/viagem/hospedagens" class="menuDesktop__box__link"
					>Reservas de Hotéis</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/viagem/aluguel-de-carro"
					class="menuDesktop__box__link"
					>Aluguel de Carro</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/viagem/pacotes-de-viagem"
					class="menuDesktop__box__link"
					>Pacotes de Viagem</a
				>
			</div>
			<a href="https://latampass.latam.com/pt_br/vem-com-pontos">
				<div class="menuDesktop__box__linksBtnOne">
					<p>Iniciante no mundo de milhas?</p>
					<i class="fa fa-arrow menuDesktop__box__linksBtnOne__icon"></i>
				</div>
			</a>
		</div>

		<div class="menuDesktop__box__links">
			<p class="menuDesktop__box__linksTitle">NO DIA A DIA</p>
			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/ganhar-pontos/cartoes-de-bancos"
					class="menuDesktop__box__link"
					>Transfira do cartão de crédito</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://latampass.latam.com/pt_br/latampass-itaucard" class="menuDesktop__box__link"
					>Cartão LATAM Pass Itaú</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://latampass.latam.com/pt_br/clube" class="menuDesktop__box__link"
					>Clube LATAM Pass</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://www.pontosmultiplus.com.br/facilidades/compradepontos"
					class="menuDesktop__box__link"
					>Comprar milhas</a
				>
			</div>

			<div class="menuDesktop__box__linksBox">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/junte-pontos/km-de-vantagens"
					class="menuDesktop__box__link"
					>Postos Ipiranga</a
				>
			</div>
		</div>
		<div class="menuDesktop__box__links">
			<p class="menuDesktop__box__linksTitle" style="visibility: hidden">-ESPAÇO-</p>
			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://resgatepontos.latampass.latam.com/home" class="menuDesktop__box__link"
					>Shopping LATAM Pass</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://gastronomia.latampass.latam.com/gastronomia" class="menuDesktop__box__link"
					>LATAM Pass Gastronomia</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://latampass.latam.com/pt_br/viagem/seguros" class="menuDesktop__box__link"
					>Seguros</a
				>
			</div>

			<div class="menuDesktop__box__linksBox">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos/transferir"
					class="menuDesktop__box__link"
					>Transfira entre contas LATAM Pass</a
				>
			</div>
		</div>

		<div class="menuDesktop__box__linksBtn">
			<a href="https://latampass.latam.com/pt_br/latampass-itaucard">
				<div class="menuDesktop__box__linksBtnTwo">
					<p class="menuDesktop__box__linksBtnTwo__title">
						LATAM<br />
						Pass<br />
						Itaú
					</p>
					<p class="menuDesktop__box__linksBtnTwo__text">
						Peça o seu <i class="fa fa-arrow" style="font-size: 7px; margin-left: 2px"></i>
					</p>
					<img class="menuDesktop__box__linksBtnTwo__img" src="./../assets/images/image33.png" />
				</div>
			</a>
		</div>
	</div>

	<!--menu3-->
	<div class="menuDesktop__box" *ngIf="menuSelected == 'menu3'">
		<div class="menuDesktop__box__links">
			<p class="menuDesktop__box__linksTitle">NA SUA VIAGEM</p>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://latampass.latam.com/pt_br/passagens" class="menuDesktop__box__link"
					>Passagens</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://resgatepontos.latampass.latam.com/trocar-pontos/accor/transferencia-de-pontos-para-accor/TA008"
					class="menuDesktop__box__link"
					>Reserva de Hoteis</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/viagem/aluguel-de-carro"
					class="menuDesktop__box__link"
					>Aluguel de carros</a
				>
			</div>

			<div class="menuDesktop__box__linksBox">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/viagem/pacotes-de-viagem"
					class="menuDesktop__box__link"
					>Pacotes de Viagem</a
				>
			</div>
		</div>

		<div class="menuDesktop__box__links">
			<p class="menuDesktop__box__linksTitle">NO DIA A DIA</p>
			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://resgatepontos.latampass.latam.com/home" class="menuDesktop__box__link"
					>Produtos</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://shopping.latampass.latam.com/pt_br/hotsite?b=combustiveis"
					class="menuDesktop__box__link"
					>Vale Combustivel</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://resgatepontos.latampass.latam.com/trocar-pontos/categoria/varejo-virtual/315?ordenacao=SCORE&habilitado=true&publicado=true&produtoHome=false&page=1&pageSize=12&categoriasGopoints=303&categoriaPai=303&relevancia=SCORE"
					class="menuDesktop__box__link"
					>Voucher</a
				>
			</div>

			<div class="menuDesktop__box__linksBox">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://resgatepontos.latampass.latam.com/trocar-pontos/categoria/doacao-de-pontos/345?ordenacao=SCORE&habilitado=true&publicado=true&produtoHome=false&page=1&pageSize=12&&categoriasGopoints=315&categoriaPai=1&relevancia=SCORE"
					class="menuDesktop__box__link"
					>Doação de milhas</a
				>
			</div>
			<a href="https://latampass.latam.com/pt_br/vem-com-pontos">
				<div class="menuDesktop__box__linksBtnOne">
					<p>Iniciante no mundo de milhas?</p>
					<i class="fa fa-arrow menuDesktop__box__linksBtnOne__icon"></i>
				</div>
			</a>
		</div>
		<div class="menuDesktop__box__linksBtn">
			<a href="https://latampass.latam.com/pt_br/latampass-itaucard">
				<div class="menuDesktop__box__linksBtnTwo">
					<p class="menuDesktop__box__linksBtnTwo__title">
						LATAM<br />
						Pass<br />
						Itaú
					</p>
					<p class="menuDesktop__box__linksBtnTwo__text">
						Peça o seu <i class="fa fa-arrow" style="font-size: 7px; margin-left: 2px"></i>
					</p>
					<img class="menuDesktop__box__linksBtnTwo__img" src="./../assets/images/image33.png" />
				</div>
			</a>
		</div>
	</div>

	<!--menu4-->
	<div class="menuDesktop__box" *ngIf="menuSelected == 'menu4'">
		<div class="menuDesktop__box__links">
			<p class="menuDesktop__box__linksTitle">EM SEU VOO</p>
			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="
https://latampass.latam.com/pt_br/categorias-elite/upgrade-de-cabine"
					class="menuDesktop__box__link"
					>Upgrade de Cabine</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="
https://www.latamairlines.com/br/pt/central-ajuda/perguntas/remarcacoes/passagens/adiantar-adiar-voo-hoje"
					class="menuDesktop__box__link"
					>Antecipar ou postergar voo</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="
https://www.latamairlines.com/br/pt/experiencia/prepare-sua-viagem/bagagem"
					class="menuDesktop__box__link"
					>Bagagem</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="
https://www.latamairlines.com/br/pt/experiencia/prepare-sua-viagem/assentos"
					class="menuDesktop__box__link"
					>Seleção de assento</a
				>
			</div>

			<div class="menuDesktop__box__linksBox">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="
https://latampass.latam.com/pt_br/viagem/assento-latam-mais"
					class="menuDesktop__box__link"
					>Assento LATAM+</a
				>
			</div>
		</div>

		<div class="menuDesktop__box__links">
			<p class="menuDesktop__box__linksTitle">NO AEROPORTO</p>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="
https://latampass.latam.com/pt_br/categorias-elite"
					class="menuDesktop__box__link"
					>Acesso Premium</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="
https://latampass.latam.com/pt_br/categorias-elite"
					class="menuDesktop__box__link"
					>Sala Vip LATAM</a
				>
			</div>

			<div class="menuDesktop__box__linksBox">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="
https://latampass.latam.com/pt_br/categorias-elite"
					class="menuDesktop__box__link"
					>Embarque Premium</a
				>
			</div>
		</div>
		<div class="menuDesktop__box__links">
			<p class="menuDesktop__box__linksTitle">TUDO SOBRE</p>
			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="
https://latampass.latam.com/pt_br/categorias-elite"
					class="menuDesktop__box__link"
					>Categorias Elite</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="
https://latampass.latam.com/pt_br/categorias-elite"
					class="menuDesktop__box__link"
					>Todos os benefícios</a
				>
			</div>

			<a href="https://latampass.latam.com/pt_br/vem-com-pontos">
				<div class="menuDesktop__box__linksBtnOne">
					<p>Iniciante no mundo de milhas?</p>
					<i class="fa fa-arrow menuDesktop__box__linksBtnOne__icon"></i>
				</div>
			</a>
		</div>

		<div class="menuDesktop__box__linksBtn">
			<a href="https://latampass.latam.com/pt_br/latampass-itaucard">
				<div class="menuDesktop__box__linksBtnTwo">
					<p class="menuDesktop__box__linksBtnTwo__title">
						LATAM<br />
						Pass<br />
						Itaú
					</p>
					<p class="menuDesktop__box__linksBtnTwo__text">
						Peça o seu <i class="fa fa-arrow" style="font-size: 7px; margin-left: 2px"></i>
					</p>
					<img class="menuDesktop__box__linksBtnTwo__img" src="./../assets/images/image33.png" />
				</div>
			</a>
		</div>
	</div>

	<!--menu5-->
	<div class="menuDesktop__box" *ngIf="menuSelected == 'menu5'">
		<div class="menuDesktop__box__links">
			<p class="menuDesktop__box__linksTitle">SOBRE O PROGRAMA</p>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://latampass.latam.com/pt_br/sobre-o-programa" class="menuDesktop__box__link"
					>Conheça LATAM Pass</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://latampass.latam.com/pt_br/fazer-cadastro" class="menuDesktop__box__link"
					>Como se Inscrever</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://latampass.latam.com/pt_br/categorias-elite" class="menuDesktop__box__link"
					>Como ser um sócio elite</a
				>
			</div>

			<div class="menuDesktop__box__linksBox">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a href="https://latampass.latam.com/pt_br/vem-com-pontos" class="menuDesktop__box__link"
					>Vem com milhas</a
				>
			</div>
		</div>
		<div class="menuDesktop__box__links">
			<p class="menuDesktop__box__linksTitle" style="visibility: hidden">-ESPAÇO-</p>
			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos/solicitar-pontos-em-voo/validade-pontos"
					class="menuDesktop__box__link"
					>Vencimento de milhas</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos/renovar"
					class="menuDesktop__box__link"
					>Renovar meus milhas</a
				>
			</div>

			<div class="menuDesktop__box__linksBox" style="border-bottom: 1px solid #e1e5f5">
				<i class="fa fa-seta-cheia" style="font-size: 30px"></i>
				<a
					href="https://latampass.latam.com/pt_br/descubra-latam-pass/termos-e-condicoes"
					class="menuDesktop__box__link"
					>Termos e Condições</a
				>
			</div>
			<a href="https://latampass.latam.com/pt_br/vem-com-pontos">
				<div class="menuDesktop__box__linksBtnOne">
					<p>Iniciante no mundo de milhas?</p>
					<i class="fa fa-arrow menuDesktop__box__linksBtnOne__icon"></i>
				</div>
			</a>
		</div>
		<div class="menuDesktop__box__linksBtn">
			<a href="https://latampass.latam.com/pt_br/latampass-itaucard">
				<div class="menuDesktop__box__linksBtnTwo">
					<p class="menuDesktop__box__linksBtnTwo__title">
						LATAM<br />
						Pass<br />
						Itaú
					</p>
					<p class="menuDesktop__box__linksBtnTwo__text">
						Peça o seu <i class="fa fa-arrow" style="font-size: 7px; margin-left: 2px"></i>
					</p>
					<img class="menuDesktop__box__linksBtnTwo__img" src="./../assets/images/image33.png" />
				</div>
			</a>
		</div>
	</div>
</div>
<div class="menuUser" *ngIf="showMenuUser">
	<ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async } as obj">
		<ng-container *ngIf="obj.isLoggedIn">
			<button class="menuUser__btn" routerLink="/gastronomia">Gastronomia</button>
			<button class="menuUser__btn" routerLink="/reservas-e-pagamento">
				Reservas e pagamento
				<span style="position: relative" *ngIf="hasPaymentReady">
					<div
						class="badge__circle"
						style="--width-c: 22px; --font-size-n: 14px; --translate-x: 140%; --translate-y: 0%"
					>
						<span class="badge__circle__notification">1</span>
					</div>
				</span>
			</button>
			<button class="menuUser__btn" routerLink="/carteira">Carteira</button>
			<button class="menuUser__btn">
				<a href="https://latampass.com/myaccount">Minha conta</a>
			</button>
			<button class="menuUser__btnExit" (click)="logout()">Sair agora</button>
		</ng-container>
		<ng-container *ngIf="!obj.isLoggedIn">
			<a [href]="signUpUrl">
				<button id="isc" style="color: var(--color-black)">Cadastre-se</button>
			</a>
			<a [href]="loginUrl">
				<button id="login" style="color: var(--color-black)">Login</button>
			</a>
		</ng-container>
	</ng-container>
</div>
<div (mouseover)="exitMenu()" class="funds" *ngIf="showMenuUserDesk || showDivDesktop || showMenuUser"></div>
