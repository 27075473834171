<div class="checkin" *ngIf="statusAvailabities$ | async as statusAvailabities">
	<ng-container *ngIf="statusAvailabities === 'pending'">
		<app-spinner></app-spinner>
	</ng-container>
	<ng-container *ngIf="statusAvailabities === 'success'">
		<div class="checkin__info">
			<div>
				<h1 class="checkin__infoTitle">Nome</h1>
				<h2 class="checkin__infoText" *ngIf="selectedClient$ | async as client">
					{{ client.fullName }}
				</h2>
			</div>
			<div>
				<h1 class="checkin__infoTitle">Data</h1>
				<h2 class="checkin__infoText">
					<p>{{ checkInDate.dayFormatted }}</p>
				</h2>
			</div>
			<div>
				<ch-select-box
					*ngIf="partySizes"
					[items]="partySizes"
					(itemWasSelected)="setPartySize($event)"
				></ch-select-box>
			</div>
		</div>

		<div class="checkin__alert">
			<i class="fa fa-info"></i>
			<p class="checkin__alertText">
				Faça o check-in para habilitar o pagamento no portal Latam Gastronomia e ganhe milhas.
			</p>
		</div>

		<div>
			<form [formGroup]="form" class="checkin__confirm">
				<label class="switch">
					<input id="confirm" type="checkbox" formControlName="confirm" />
					<span class="slider round"></span>
				</label>
				<label for="confirm">
					<p class="checkin__confirmText">Confirmo que estou acomodado em uma mesa</p>
				</label>
			</form>
		</div>

		<app-divisor top="75px" bottom="24px"></app-divisor>

		<button
			class="checkin__btn"
			[disabled]="isDisableForm"
			[ngClass]="{ 'disabled-button': isDisableForm }"
			(click)="confirmCheckin()"
		>
			Confirmar check-in
		</button>
	</ng-container>
	<ng-container *ngIf="statusAvailabities === 'unavailable'">
		<app-alert template="unavailableCheckin"></app-alert>
		<app-divisor></app-divisor>
		<!--<p *ngIf="errorAvailabilities$ | async as error" class="checkin__error">
			{{ error }}
		</p>-->
		<div class="unavailable">
			<p class="unavailable__title">Horários disponíveis do check-in</p>
			<div style="display: flex; gap: 5px">
				<p class="unavailable__info">Início:</p>
				<p class="time" *ngIf="statusCheckin$ | async as status">{{ status.startTime }}</p>
			</div>
			<div style="display: flex; gap: 5px">
				<p class="unavailable__info">Fim:</p>
				<p class="time" *ngIf="statusCheckin$ | async as status">{{ status.endTime }}</p>
			</div>
		</div>
		<div style="height: 30px"></div>
	</ng-container>
</div>
