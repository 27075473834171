<ul class="breadcrumbs px-16">
	<li class="breadcrumbs__item"><a href="https://latampass.latam.com/">LATAM Pass</a></li>
	<div class="breadcrumbs__icon"><i class="fa fa-arrow"></i></div>
	<li class="breadcrumbs__item">
		<a href="https://latampass.latam.com/pt_br/juntar-milhas"> Juntar milhas </a>
	</li>
	<ng-container>
		<ng-container *ngFor="let breadcrumb of breadcrumbs">
			<div class="breadcrumbs__icon"><i class="fa fa-arrow"></i></div>
			<li
				class="breadcrumbs__item"
				[routerLink]="[breadcrumb?.url]"
				routerLinkActive="item--active"
				[routerLinkActiveOptions]="{ exact: true }"
			>
				{{ breadcrumb?.title }}
			</li>
		</ng-container>
	</ng-container>
</ul>
