<app-header></app-header>

<div class="venueTop">
	<div class="venueTop__breadcrumb">
		<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
	</div>
	<div class="venueTop__carrossel px-desk">
		<app-carrossel
			*ngIf="selectedVenue$ | async as selectedVenue "
			[imagesDesk]="selectedVenue.coverURLsDesktop"
			[imagesMobile]="selectedVenue.coverURLsMobile"
			[showButton]="false"
		></app-carrossel>
	</div>
</div>

<div class="venueBody px-16 px-desk">
	<div class="loading" *ngIf="isLoadingSelectedVenue$ | async">
		<app-spinner></app-spinner>
	</div>
	<ng-container *ngIf="selectedVenue$ | async as selectedVenue ">
		<div class="bodyInside mobile" *ngIf="isMobile">
			<app-venue-info
				[name]="selectedVenue.name"
				[address]="selectedVenue.address.value"
				[cuisines]="selectedVenue.cuisines"
			></app-venue-info>

			<div class="bodyInside__buttonsModais">
				<a
					*ngIf="selectedVenue.hasMenu"
					class="button"
					[href]="selectedVenue.liveMenuURL"
					target="_blank"
					><i class="fa fa-menu"></i>Ver cardápio</a
				>
				<a *ngIf="!selectedVenue.hasMenu" class="button button--disabled"
					><i class="fa fa-menu"></i>Ver cardápio</a
				>
				<button (click)="openModal(modal.operationHours)" class="button">
					<i class="fa fa-schedules"></i>Ver horários
				</button>
			</div>

			<hr class="hr bodyInside__hr" />

			<div class="bodyInside__benefits">
				<app-benefits [benefit]="selectedVenue.benefits"></app-benefits>
			</div>

			<div class="bodyInside__alert">
				<app-alert></app-alert>
			</div>

			<div class="bodyInside__mapMobile">
				<a [href]="selectedVenue.mapsLink" target="_blank">
					<img [src]="selectedVenue.mapsMobile" />
				</a>
			</div>

			<div class="bodyInside__reserva">
				<button
					class="btn btn--primary"
					(click)="goToReservationMobile()"
					*ngIf="selectedVenue.reservation?.enabled"
				>
					Reservar uma mesa
				</button>
				<button
					*ngIf="selectedVenue.hasCheckin.enabled"
					class="btn btn--inverted"
					(click)="goToCheckinMobile()"
					[ngStyle]="{opacity: selectedVenue.hasCheckin.available ? '1':'0.5'}"
				>
					Fazer check-in
				</button>
			</div>
		</div>

		<div class="desk desktop" *ngIf="!isMobile">
			<div class="desk__info">
				<div class="bodyInside">
					<app-venue-info
						[name]="selectedVenue.name"
						[address]="selectedVenue.address.value"
						[cuisines]="selectedVenue.cuisines"
					></app-venue-info>

					<div class="bodyInside__buttonsModais">
						<a
							*ngIf="selectedVenue.hasMenu"
							class="button"
							[href]="selectedVenue.liveMenuURL"
							target="_blank"
							><i class="fa fa-menu"></i>Ver cardápio</a
						>
						<a *ngIf="!selectedVenue.hasMenu" class="button button--disabled"
							><i class="fa fa-menu"></i>Ver cardápio</a
						>
						<button (click)="openModal(modal.operationHours)" class="button">
							<i class="fa fa-schedules"></i>Ver horários
						</button>
					</div>

					<hr class="hr bodyInside__hr" />

					<div class="bodyInside__benefits">
						<app-benefits [benefit]="selectedVenue.benefits"></app-benefits>
					</div>

					<div class="bodyInside__alert">
						<app-alert></app-alert>
					</div>

					<div class="bodyInside__reserva">
						<button
							class="btn btn--primary"
							(click)="goToReservationDesk()"
							*ngIf="selectedVenue.reservation?.enabled"
						>
							Reservar uma mesa
						</button>
						<button
							*ngIf="selectedVenue.hasCheckin.enabled"
							class="btn btn--inverted"
							(click)="goToCheckinDesk()"
							[ngStyle]="{opacity: selectedVenue.hasCheckin.available ? '1':'0.5'}"
						>
							Fazer check-in
						</button>
					</div>
				</div>
			</div>

			<div class="desk__mapDesktop">
				<a [href]="selectedVenue.mapsLink" target="_blank">
					<img [src]="selectedVenue.mapsDesk" />
				</a>
			</div>
		</div>

		<app-modal [id]="modal.operationHours" top="25%">
			<app-modal-schedules
				[id]="modal.operationHours"
				[operationHours]="selectedVenue.operationHours"
			></app-modal-schedules>
		</app-modal>
	</ng-container>
</div>

<div class="space-map"></div>

<app-footer></app-footer>

<div class="space-fixed"></div>

<!-- Both desktop and mobile modals -->
<app-modal [id]="modal.login" top="35%" [preventOutsideClickClose]="true">
	<app-modal-login [id]="modal.login"></app-modal-login>
</app-modal>

<app-modal [id]="modal.error" top="18%">
	<app-modal-basic title="Algum erro aconteceu" [id]="modal.error" *ngIf="error$ | async as errorData">
		{{errorData}}
	</app-modal-basic>
</app-modal>

<app-modal [id]="modal.registerCard" top="18%">
	<app-modal-basic title="Cadastrar cartão" [hasClose]="false">
		Você ainda não tem um cartão cadastrado para pagar a conta nos restaurantes pela LATAM Gastronomia e
		ganhar milhas.
		<button class="btn btn--primary" routerLink="/carteira" *ngIf="!isMobile">Cadastrar Cartão</button>
		<button class="btn btn--primary" routerLink="/carteira/adicionar-cartao" *ngIf="isMobile">
			Cadastrar Cartão
		</button>
		<button class="btn btn--ghost" (click)="closeModal(modal.registerCard)">
			Não desejo ganhar milhas
		</button>
	</app-modal-basic>
</app-modal>

<app-handle-login-token page="venue-details"></app-handle-login-token>

<!-- Only desktop modal -->
<app-modal [id]="modal.reservation" top="10%" [preventOutsideClickClose]="true" *ngIf="!isMobile">
	<app-modal-reservation *ngIf="openReservation" (close)="closeReservationDesk()"></app-modal-reservation>
</app-modal>

<app-modal [id]="modal.reservationSuccess" top="10%" *ngIf="!isMobile">
	<div class="wrapper-modal">
		<app-confirm-reservation></app-confirm-reservation>
	</div>
</app-modal>

<app-modal [id]="modal.checkin" top="10%" *ngIf="!isMobile">
	<div class="wrapper-modal">
		<div style="padding: 16px">
			<app-modal-header (close)="closeModal(modal.checkin)">Check-in</app-modal-header>
			<h2 class="modalVenueName" *ngIf="selectedVenue$ | async as venue">{{ venue.name }}</h2>
		</div>
		<app-checkin *ngIf="openCheckin"></app-checkin>
	</div>
</app-modal>

<app-modal [id]="modal.checkinSuccess" top="10%" *ngIf="!isMobile">
	<div class="wrapper-modal">
		<app-checkin-success></app-checkin-success>
		<div style="height: 30px"></div>
	</div>
</app-modal>
