<div class="order">
	<div *ngIf="order?.payment">
		<div class="order__date">
			<p class="order__dateDay">{{ order?.date?.day }}</p>
			<p class="order__dateMonth">{{ order?.date?.month }}</p>
		</div>

		<div class="order__venueInfo">
			<app-venue-info
				[name]="order?.venue?.name"
				[address]="order?.venue?.address?.value"
			></app-venue-info>
		</div>

		<div class="order__footer">
			<button class="order__reserve">{{ order?.intent }} às {{ order?.date?.time }}</button>
		</div>

		<div style="margin-top: 26px">
			<app-ticket
				label="Valor da conta"
				[data]="order!.payment!.intialValue / 100 | currency : 'BRL'"
			></app-ticket>
		</div>

		<div style="margin-top: 16px">
			<app-ticket
				label="Gorjeta garçom"
				[data]="order!.payment!.gratuityValue / 100 | currency : 'BRL'"
			></app-ticket>
		</div>

		<div style="margin-top: 16px">
			<app-ticket
				label="Valor total pago"
				[data]="order!.payment!.totalValue / 100 | currency : 'BRL'"
			></app-ticket>
		</div>

		<div style="margin-top: 16px">
			<app-ticket
				label="Milhas recebidos"
				[data]="order?.payment?.points"
				[tooltip]="true"
				[days]="2"
			></app-ticket>
		</div>

		<div style="margin-top: 16px">
			<app-ticket label="Cartão de crédito" [data]="order?.payment?.lastFourDigits"></app-ticket>
			<!-- [creditCard]="chooseBrand.get(order?.payment.brand)" -->
		</div>

		<div style="margin-top: 16px">
			<app-ticket label="Código de pagamento" [data]="order?.payment?.code"></app-ticket>
		</div>

		<div style="height: 24px"></div>
	</div>

	<div *ngIf="!order?.payment">
		<div class="order__date">
			<p class="order__dateDay">{{ order?.date?.day }}</p>
			<p class="order__dateMonth">{{ order?.date?.month }}</p>
		</div>

		<div class="order__venueInfo">
			<app-venue-info
				[name]="order?.venue?.name"
				[address]="order?.venue?.address?.value"
			></app-venue-info>
		</div>
		<div class="order__footer">
			<button class="order__reserve">{{ order?.intent }} às {{ order?.date?.time }}</button>
			<button class="order__cancel" (click)="cancelClicked.emit()" *ngIf="canCancel$ | async">
				Cancelar reserva
			</button>
		</div>
		<div class="order__benefits">
			<app-benefits [benefit]="order?.benefits"></app-benefits>
		</div>

		<div>
			<app-alert></app-alert>
		</div>
	</div>
</div>
